import style from "./chatbody.module.scss";
import { useContext, useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { IframeContext } from "../../iFrameContext";
import logo from "../../Images/avatarImg.svg";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Loader from "../loader/loader";
import { Formik, Form, FormikHelpers } from "formik";
import { INITIAL_VALUES, VALIDATION_SCHEMA } from "./schema";
import Sendbtn from "../../../src/Images/sendBtn_new.svg";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Mic } from "@mui/icons-material";
import { StyledFooter, StyledModalContainer } from "./styledComponents";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
import { OxylymServices } from "../../utils/api/services/apiServices";
import React from "react";
import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";
import CardInfo from "./cardInfo";
import InfoText from "./infoText";
import MeetingCard from "./meetingCard";
import JobDetailsForm from "./jobDetailsForm";
import ResumeUpload from "./uploadResume";
import next from "../../Images/next.svg";
import JobListing from "./jobListing";
import CustomMultiSelect from "../custom-multiselect";
import { useStopwatch, useTimer } from 'react-timer-hook';
import RecordingGif from '../../Images/recording.gif'
import { MultiSelectContext } from "../../context/multiSelectProvider";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
// import  '../../Sounds/mixkit-correct-answer-tone-2870.wav';

interface ChatBodyProps {
  responseData?: any; // Data received from ChatFooter
  onResponseData?: any;
  fulfillmentTexts:any;
  setFulfillmentTexts:any;
  timeoutResponse: any; 
  startIdleTimer:any;
  sessionId: string | null;
  endSession:string | undefined;
  updateEndSession:Dispatch<SetStateAction<string>>
}

const ChatBody: React.FC<ChatBodyProps> = ({
  responseData,onResponseData,fulfillmentTexts, setFulfillmentTexts,timeoutResponse, startIdleTimer, sessionId, endSession, updateEndSession,
}) => {
  const { iframeData } = useContext(IframeContext);
  const [fileSizeError, setFileSizeError] = useState<string>("");
  const [chipsOptions, setChipsOptions] = useState<any[]>([]);
  const [chipsetSelect, setChipsetSelect] = useState(false);
  // const [sessionId, setSessionId] = useState("");
  
  const [selectedFile, setSelectedFile] = useState<File | null>(null);  //for getting the selected file from 'uploadResume' component
  // const fileInputRef = useRef<HTMLInputElement | null>(null);
  // const [_activeItems, _setActiveItems] = useState<any>({}); //for highlighting the selected chip
 
  const [subject, setSubject] = useState(""); // as payload for upload resume
  const [messageContent, setMessageContent] = useState(""); // as payload for upload resume
  const [toEmail, setToEmail] = useState("");// as payload for upload resume
  const [fromEmail,setFromEmail] = useState(""); // as payload for upload resume
  const [combinedValuesInParent, setCombinedValuesInParent] = useState("");
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [scrollHeight,setScrollHeight] = useState(0);
  const [payloadCount,setPayloadCount] = useState(0)
  let [formResponse, setFormResponse] = useState("");
  let isHorizontal = useRef<boolean>(false);
  // const [isValid, updateIsValid] = useState(false);
  const { isValid, updateValue} = useContext(MultiSelectContext);
  let isTypeInfo = useRef<boolean>(false);
  // let isMeetingCard = useRef<boolean>(false);
  let isTypeForm = useRef<boolean>(false);
  // let isTypeUpload = useRef<boolean>(false);
  // const [transcript, setTranscript] = useState('');
  const { transcript, resetTranscript, listening} = useSpeechRecognition();  const id = useRef(1); //for highlighting the selected chip
  let sevicesList = useRef<boolean>(false); //flag to do one API call in onSubmitHandler based on incoming response text
  let isInitialState = useRef<boolean>(true);// to set "Say hi to begin"
  const itemwidth = 290; //for left n right scroll of service cards
  let scrollbar = 0;
  const formChatRef= useRef<HTMLAudioElement | null>(null);
  const responsesReceivedRef = useRef<HTMLAudioElement | null>(null);
  const [activeChips, setActiveChips] = useState<{ [uid: number]: number }>(
    {});
  // const chipClickRef = useRef<HTMLAudioElement | null>(null);
  const [responseDatachip, setResponseDatachip] = useState<any>([]);
  //for openAI voice to text
  const [isListening, setIsListening] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
  const TRANSCRIPTION_API_URL = "https://api.openai.com/v1/audio/transcriptions";
  const recorderRef = useRef<MediaRecorder | null>(null); // Store recorder in a ref to avoid stale state
  const [responseQuery,setResponseQuery] = useState<string>('');
  const [openAiLoader, setOpenAiLoader] = useState<boolean>(false);

  const audioChunksRef = useRef<Blob[]>([]);

  // console.log("isRec",isRecording)

  const moveleft = (event: any, idnum: number) => {
    // console.log("moveleft - itemwidth =",itemwidth + "scrollbar =",scrollbar);
    scrollbar = scrollbar <= itemwidth ? -itemwidth : scrollbar - itemwidth;
    document.getElementById(idnum.toString())?.scrollTo(scrollbar, 0);
  };
 
  function getResponse(data:any){
    processResponse(data?.payload);
    updateEndSession(data?.EndSession?.toLowerCase() === "true"? "True" : "false");
    // console.log("updateEndSession(data?.EndSession) = ",data?.EndSession);
    playAudio(responsesReceivedRef,500);

  }
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });
  
  useEffect(() => {
    if (fulfillmentTexts) {
      setFulfillmentTexts((prevFulfillmentTexts:any) => {
        const updatedFulfillmentTexts = [...prevFulfillmentTexts];
        // console.log(iframeData);

        updatedFulfillmentTexts[0] = {
          ...updatedFulfillmentTexts[0],
          text: `Hello, I am ${iframeData?.botName}, a virtual assistant from ${iframeData?.companyName}. I would like to help you get a head start with our assessment platform.`,
          // text:''
        };
        // console.log(updatedFulfillmentTexts);
        return updatedFulfillmentTexts;
      });
    }
  }, [iframeData]);

  const moveright = (event: any, idnum: number, textlength: number) => {
    // console.log("moveright - itemwidth =",itemwidth + "scrollbar =",scrollbar +"textlength = ",textlength);
    scrollbar = scrollbar + itemwidth >= textlength * itemwidth
        ? textlength * itemwidth
        : scrollbar + itemwidth;
    document.getElementById(idnum.toString())?.scrollTo(scrollbar, 0);
 
  };

  const onChipClick = async (i: number, chipText: string) => {
    // console.log(chipText,"chipText")
    setChipsetSelect(true);
    // playAudio(chipClickRef, 0);
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      {
        uid: 0,
        text: "Dummy value",
        flag: true,
        isUser: false,
        option: [],
        textLength: 2000
      },
    ]);

    startIdleTimer();
    const data = {
      agent_id:  process.env.REACT_APP_AGENT_ID,
      session_end: false,
      session_id: sessionId,
      text: chipText,
      timezone: "Asia/Calcutta",
      translate_code: "en",
    };

    try {
      // console.log("1")
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      // console.log("sdads 7",);
      updateValue(false);
      const newResponseData = await response.json();
      setResponseDatachip(newResponseData); 
      setFormResponse(newResponseData)
      // console.log("response on chipclick = ",newResponseData);
      const fulfillmentText = newResponseData?.payload;
      // console.log("newResponseData = ",newResponseData);
      // console.log(fulfillmentText,"textAdas")
      updateEndSession(newResponseData?.EndSession?.toLowerCase() === "true"? "True" : "false");


      if(fulfillmentText.length === 0){
        setFulfillmentTexts((prevFulfillmentTexts:any) => {
          // console.log("asdadsadasdsasd-----------------------------")
          const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
          return updatedFulfillmentTexts;
        });
      }
      // console.log("fulfillmentText",fulfillmentText[0]?.richContent?.[0]?.type);
      if(fulfillmentText[0]?.richContent?.[0]?.type === 'job_info'){
        jobListingResponse(fulfillmentText);
        // console.log("hreeeeeeee")
      }else{
        processResponse(fulfillmentText);
      }
      playAudio(responsesReceivedRef,500);
    } catch (error) {}
  };

  useEffect(()=>{
    // console.log("isValid = ",isValid);
  },[isValid])


  //for getting "subject,messageContent,toEmail,fromEmail" values from previous response & send them in uplaod resume API in handleResumeSend()
  useEffect(() => {
    if (responseDatachip?.payload !== undefined && responseDatachip?.payload.length > 0) {
        // console.log("responseDatachip+++++chatBody", responseDatachip);
       
        responseDatachip?.payload.forEach((item: any) => {
            if (item.richContent && item.richContent.length > 0) {
                const firstRichContent = item.richContent[0];

                if (firstRichContent.type === "email") {
                    // console.log("Matching chip_type found");
                    const options1 = JSON.parse(firstRichContent.title.replace(/'/g, '"'));
                    const subject = options1.subject;
                    const messageContent = options1.message_content;
                    const toEmail = options1.to_email;
                    const fromEmail = options1.from_email;
                    
                    setSubject(subject);
                    setMessageContent(messageContent);
                    setToEmail(toEmail);
                    setFromEmail(fromEmail);
                    // console.log(options1,subject,messageContent,toEmail,fromEmail)
                }
            }
        });
    }
}, [responseDatachip]);
 
  const handleChipClick = (i: number, chipText: string, uid: number) => {
    const numericDataObject: Record<string, number> = {};
      for (const key in activeChips) {
          if (activeChips.hasOwnProperty(key)) {
              numericDataObject[key] = parseFloat(key);
          }
      }
      // Check if the value exists in the object
      const exists: boolean = numericDataObject.hasOwnProperty(uid);

      if (exists) {
          // console.log(`The value ${uid} exists in the object.`);
      } else {
          onChipClick(i, chipText);
          setActiveChips({ ...activeChips, [uid]: i });
          // console.log(`The value ${uid} does not exist in the object.`);
      }
          // Set the active chip for the current set
          // console.log("activeChips =",activeChips);
  };

  const[multiChip,updateMultiChip] = useState<any>([])
  const handleMultiChipClick = (i: number, chipText: string, uid: number,option:any) => {
    const numericDataObject: Record<string, number> = {};
    option.status = !option.status;
    // console.log("option = ",option + "activeChips = ",activeChips)
      for (const key in activeChips) {
          if (activeChips.hasOwnProperty(key)) {
              numericDataObject[key] = parseFloat(key);
          }
      }
      // console.log("numericDataObject after loop = ",numericDataObject)
      // Check if the value exists in the object
      const exists: boolean = numericDataObject.hasOwnProperty(uid);
      // console.log("exists= ",exists)
      // updateMultiChip((multiChip:any)=>[...multiChip,chipText]);
      updateMultiChip((multiChip:any)=>{
        if(option.status){
          // console.log("multiChip in updatemultiChip= ",multiChip + "chipText = ",chipText)
          return [...multiChip,chipText]
        }
        else{
          return multiChip.filter((item:any)=>item !== chipText)
        }
      })
      if (exists) {
          // console.log(`The value ${uid} exists in the object.`);
      } else {}
        //  console.log(fulfillmentTexts,"fulfillmentTexts")
  };
  
// handle multiChip ends

  function handleMultiSelectApi(i:number,text:string){
    // console.log(chipText,"chipText")
    onChipClick(i, text);
  }

  const isChipActive = (uid: number, index: number) => {
    return activeChips[uid] === index;
  };

  //function to handle the response recieved after submitting the job form
  const handleCombinedValues = async (combinedValues: string) => {
    // console.log("combinedValues",combinedValues);
    setCombinedValuesInParent(combinedValues);

//to set loader while we wait for the response
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      {
        uid: id.current++,
        text: "Dummy value",
        flag: true,
        isUser: false,
        option: [],
        textLength: 2000,
      },
    ]);

    const data = {
      agent_id: process.env.REACT_APP_AGENT_ID,
      session_end: false,
      session_id: sessionId,
      text: combinedValues,
      timezone: "Asia/Calcutta",
      translate_code: "en",
    };
  
    // console.log("2")
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    // console.log('form response as it is',response);

    if (!response.ok) {
      throw new Error("Request failed with status " + response.status);
    }

    let formResp = await response.json();
    setFormResponse(formResp);
    if(formResp?.payload.length === 0){
      setFulfillmentTexts((prevFulfillmentTexts:any) => {
        // console.log("asdadsadasdsasd-----------------------------2")

        const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
        return updatedFulfillmentTexts;
      });
    }
    updateEndSession(formResp?.EndSession?.toLowerCase() === "true"? "True" : "false");
    jobListingResponse(formResp?.payload);
    playAudio(responsesReceivedRef,500);
  };

  const onSubmitHandler = async (
    values: { chat: string },
    formikHelpers: FormikHelpers<{ chat: string }>
  ) => {
    isInitialState.current = false;
    playAudio(formChatRef,0);
    const { resetForm } = formikHelpers;

    // Check if the input contains an email with '@simelabs.com' domain
    const emailPattern = /@simelabs\.com$/i;
    if (uploadFile) {
          handleSendResume(uploadFile);
      }
    else {
      if (values.chat || responseQuery) {
        console.log("endsession = ",endSession);
        startIdleTimer();
        // if(emailPattern.test(values.chat)){
        //   alert("Come on you sneaky colleague! No '@simelabs.com' emails allowed - we're on to you!");
        // } 
        
        const data = {
          agent_id: process.env.REACT_APP_AGENT_ID,
          session_end: false,
          session_id: sessionId,
          text: endSession === "True" ? 'Hello': values.chat || responseQuery || combinedValuesInParent,
          timezone: "Asia/Calcutta",
          translate_code: "en",
        };

        // list services infoCard       
        if(sevicesList.current){
          sevicesList.current = false;
          // fulfillmentObjects is to set the values that are typed in d form to FulfillmentTexts[]
          const fulfillmentObjects = [
            {
              text: values.chat,
              flag: false, // Set flag as true for the first object to display logo,
              isUser: true,
              option: [],
              textLength: 1,
              // isHorizontal: false,
              uid: id.current++,
            },
          ];
  
        // console.log("fulfillmentObjects",fulfillmentObjects)

          setFulfillmentTexts((prevFulfillmentTexts:any) => [
            ...prevFulfillmentTexts,
            ...fulfillmentObjects,
          ]);
          playAudio(formChatRef,0);

          resetForm();
  
          setFulfillmentTexts((prevFulfillmentTexts:any) => [
            ...prevFulfillmentTexts,
            {
              text: "Dummy value",
              flag: true,
              isUser: false,
              option: [],
              textLength: 2000,
              uid: 0
            },
          ]);

         try {
          // console.log("3")
            const response = await fetch(
              process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );
            console.log(response)
      
            if (!response.ok) {
              throw new Error("Request failed with status " + response.status);
            }
      
            // this section is to display the infoCard horizontally
            const responseData = await response.json();
            setFormResponse(responseData)
            const richContentArray:any[] = [];
            const fullFillmentTextArray:any[] = [];
            let options: any[];

            console.log("in =")
            updateEndSession(responseData?.EndSession?.toLowerCase() === "true"? "True" : "false");
             const fulfillmentText = responseData?.payload;
            if(fulfillmentText.length === 0){
              setFulfillmentTexts((prevFulfillmentTexts:any) => {
          // console.log("asdadsadasdsasd-----------------------------4")
                
                const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
                return updatedFulfillmentTexts;
              });
            }

            let text: string = '';
            const newResponse = fulfillmentText?.forEach((item:any) => {
              if (item.hasOwnProperty('richContent')) {
                richContentArray.push(item.richContent);
              }
              if (item.hasOwnProperty('fullFillmentText')) {
                fullFillmentTextArray.push(item.fullFillmentText);
              }
            });
            const chipHorizontal = richContentArray?.[0]?.[0]?.[0]|| [];
            // console.log("chipHorizontal = ",chipHorizontal);
      
            //for horizontal scroll of services
            if (chipHorizontal.chip_type === "horizontal") {
              isHorizontal.current = true;            
            } else {
              isHorizontal.current = false;
            }

            //for horizontal scroll of info
            if (chipHorizontal.type === "info") {
              isTypeInfo.current = true;
              // alert("submit handler");
            } else {
              isTypeInfo.current = false;
            }
      
            setFulfillmentTexts((prevFulfillmentTexts:any) => {
              const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
              return updatedFulfillmentTexts;
            });
      
            if(richContentArray !== undefined){
              options = (richContentArray?.[0]?.[0]?.[0]?.options) || 
                        (richContentArray?.[0]?.[0]?.[0]?.options.type === "info") ||
                        (richContentArray?.[0]?.[0]?.[0]?.options.type === "chips") ||
                        (richContentArray?.[0]?.[0]?.[0]) || [];
            } else {
              options = [];
              setChipsOptions([]);
            }
          
            // console.log("fulfillmentText b4 checks= ",fullFillmentTextArray);
            if (fullFillmentTextArray && fullFillmentTextArray.length > 0 && !isHorizontal.current && !isTypeInfo.current && !isTypeForm.current) {
              const fulfillmentObjects = fulfillmentText.map(
                (text: any, index: number) => ({
                  text,
                  flag: index === 0, // Set flag as true for the first object
                  isUser: false,
                  option: index === fullFillmentTextArray?.length - 1 ? options : [],
                  textLength: fulfillmentText.length,
                  isHorizontal: false,
                  uid: id.current++,
                  isInfo: false,
                  cardOrder: false,
                  isJobForm:false,
                  isUpload:false
                })
              );
              // alert(2);
              // console.log("fulfillmentObjects !horizontal in handler= ",fulfillmentObjects)
              setFulfillmentTexts((prevFulfillmentTexts:any) => [
                ...prevFulfillmentTexts,
                ...fulfillmentObjects,
              ]);
            }
            // console.log("fulfillmentText........",fulfillmentText)
            if (fulfillmentText && fulfillmentText.length > 0 && (isHorizontal.current || isTypeInfo.current || isTypeForm.current)) {
              if (responseData.payload.richContent !== undefined) {
                options = responseData?.payload?.richContent || [];
              } else {
                options = [];
                setChipsOptions([]);
              }
      
              // console.log("fulfillmentText = ",fulfillmentText);
              // if (fulfillmentText.length === 1 && options.length > 0) {
              //   fulfillmentText.push("");
              // }
      
              const fulfillmentHorizontal = fulfillmentText.map(
                (text: any, index: number) => ({
                  text,
                  flag: index === 0,
                  isUser: false,
                  option: options,
                  textLength: 0,
                  isHorizontal: isHorizontal.current,
                  uid: id.current++,
                  isInfo: isTypeInfo.current,
                  cardOrder: false,
                  isJobForm:isTypeForm.current,
                  isUpload:false
                })
              );
              // console.log("fulfillmentHorizontal in handler= ", fulfillmentHorizontal);
      
              setFulfillmentTexts((prevFulfillmentTexts:any) => {
                const updatedFulfillmentTexts = [
                  ...prevFulfillmentTexts,
                  ...fulfillmentHorizontal,
                ];
                return updatedFulfillmentTexts;
              });
            }
      
            if (options && options.length > 0) {
              setChipsOptions(options);
            }

            resetForm();
          } catch (error) {}

        } else{
        // fulfillmentObjects is to set the values that are typed in d form to FulfillmentTexts[]
          const fulfillmentObjects = [
            {
              text: values.chat || responseQuery,
              flag: false, // Set flag as true for the first object,
              isUser: true,
              option: [],
              textLength: 1,
              isHorizontal: false,
              uid: id.current++,
              isInfo: false,
              cardOrder: false,
              isJobForm:false,
              isUpload:false
            },
          ];
  
        //  alert(2)
          setFulfillmentTexts((prevFulfillmentTexts:any) => [
            ...prevFulfillmentTexts,
            ...fulfillmentObjects,
          ]);
          playAudio(responsesReceivedRef,500);

  
          setFulfillmentTexts((prevFulfillmentTexts:any) => [
            ...prevFulfillmentTexts,
            {
              text: "Dummy value",
              flag: true,
              isUser: false,
              option: [],
              textLength: 2000,
              isHorizontal: false,
              uid: 0,
              isInfo: false,
              cardOrder: false,
              isJobForm:false,
              isUpload:false
            },
          ]);
  
          onResponseData(data, "application/json");
        }
          resetForm();
          setResponseQuery('');
        
      }
    }
  };

  const handleSendResume = async (selectedFile: File) => {
      if (selectedFile) {
      // setSelectedFile(null); // Reset selected file after sending

      setFulfillmentTexts((prevFulfillmentTexts:any) => [
        ...prevFulfillmentTexts,
        {
          text: "Dummy value",
          flag: true,
          isUser: false,
          option: [],
          textLength: 2000,
          uid: 0
        },
      ]);

      const data1 = await OxylymServices.fileSubmit({
        data: {
          agent_id: process.env.REACT_APP_AGENT_ID,
          session_end: false,
          session_id: sessionId ?? "",
          timezone: "Asia/Calcutta",
          translate_code: "en",
          subject: subject,
          to_email:toEmail,
          from_email:fromEmail,
          message_content: messageContent,
        },
        file: selectedFile,        
      });

//to display the uploaded file response "mail delivered" in bot section coz its response format is different
      setFulfillmentTexts((prevFulfillmentTexts:any) => {
        // console.log("asdadsadasdsasd-----------------------------6")

        const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
        return updatedFulfillmentTexts;
      });

      const fulfillmentObjects = [
              {
                text: selectedFile.name, // Add the selected file name here
                flag: false,
                isUser: true,
                option: [],
                textLength: 1,
                uid: id.current++
              }
            ];

            setFulfillmentTexts((prevFulfillmentTexts:any) => [
              ...prevFulfillmentTexts,
              ...fulfillmentObjects,
            ]);

       
        setFulfillmentTexts((prevFulfillmentTexts:any) => [
          ...prevFulfillmentTexts,
          {
            text: "Dummy value",
            flag: true,
            isUser: false,
            option: [],
            textLength: 2000,
            uid: 0
          },
        ]);

//api call parallel to send btn click to upload resume
        const data = {
          agent_id: process.env.REACT_APP_AGENT_ID,
          session_end: false,
          session_id: sessionId,
          text: 'resumeUploaded',
          timezone: "Asia/Calcutta",
          translate_code: "en",
        };
  
        // console.log("4")
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
  
        if (!response.ok) {
          throw new Error("Request failed with status " + response.status);
        }
  
        const resumeSentResponse = await response.json();
        setFormResponse(resumeSentResponse)
        const resumeUpload = resumeSentResponse?.payload;
        updateEndSession(resumeSentResponse?.EndSession?.toLowerCase() === "true"? "True" : "false");
        // console.log("resumeSentResponse",resumeSentResponse);
        processResponse(resumeUpload);
        playAudio(responsesReceivedRef,500)
        // resetForm();
        // setSelectedFile(null);
    }
  };

  //first useeffect of chatbody
  useEffect(() => {
    if (iframeData && responseData) {
      // const fulfillmentText = responseData?.fullFillmentText;
      const fulfillmentText = responseData?.payload;
      // console.log("fulfillmentText",fulfillmentText)
      processResponse(fulfillmentText);
    }
  }, [iframeData, responseData]);

  useEffect(() => {
    // Update CSS variable
    document.documentElement.style.setProperty('--card-background-color', iframeData?.botCardBackgroundColor as string);
    document.documentElement.style.setProperty('--card-text-color', iframeData?.botCardTextColor as string);
    document.documentElement.style.setProperty('--header-logo-color', iframeData?.headerLogoColor?iframeData?.headerLogoColor as string:"#000");
    document.documentElement.style.setProperty('--chip-Bg-Color',iframeData?.chipBgColor  as string);
    document.documentElement.style.setProperty('--chip-Text-Color',iframeData?.chipTextColor  as string);
    document.documentElement.style.setProperty('--chip-hover-background-color', iframeData?.chipHoverBackgroundColor as string);
    document.documentElement.style.setProperty('--chip-hover-text-color', iframeData?.chipHoverTextColor as string);
    document.documentElement.style.setProperty('--chip-selected-color', iframeData?.chipSelectedColor as string);
    document.documentElement.style.setProperty('--chip-selected-hover-color', iframeData?.chipSelectedHoverColor as string);
    document.documentElement.style.setProperty('--userCard-border-color', iframeData?.userCardBorderColor as string);
    document.documentElement.style.setProperty('--infoTextTitle-text-color', iframeData?.infoTextTitleTextColor as string);
    document.documentElement.style.setProperty('--meetingCradTitle-text-color', iframeData?.meetingCardTitleTextColor as string);
    document.documentElement.style.setProperty('--cardInfoChips-background-color', iframeData?.cardInfoChipsBackgroundColor as string);
    document.documentElement.style.setProperty('--cardInfoChips-border-color', iframeData?.cardInfoChipsBorderColor as string);
    document.documentElement.style.setProperty('--jobDetailsFormMain-background-color', iframeData?.jobDetailsFormMainBackgroundColor as string);
    document.documentElement.style.setProperty('--formTextArea-border-color', iframeData?.formTextAreaBorderColor as string);
    document.documentElement.style.setProperty('--formTextArea-background-color', iframeData?.formTextAreaBackgroundColor as string);
    document.documentElement.style.setProperty('--formTextArea-active-border-color', iframeData?.formTextAreaActiveBorderColor as string);
    document.documentElement.style.setProperty('--formTextArea-active-background-color', iframeData?.formTextAreaActiveBackgroundColor as string);
    document.documentElement.style.setProperty('--formSubmitBtn-background-color', iframeData?.formSubmitBtnBackgroundColor as string);
    document.documentElement.style.setProperty('--uploadBtns-background-color', iframeData?.uploadBtnsBackgroundColor as string);
    document.documentElement.style.setProperty('--uploadBtns-text-color', iframeData?.uploadBtnsTextColor as string);
    document.documentElement.style.setProperty('--chip-selected-background-color', iframeData?.chipSelectedBackgroundColor as string);
    document.documentElement.style.setProperty('--chip-selected-border-color', iframeData?.chipSelectedBorderColor as string);
    document.documentElement.style.setProperty('--chip-selected-hover-border-color', iframeData?.chipSelectedHoverBorderColor as string);
    document.documentElement.style.setProperty('--enabledBtn-background-color', iframeData?.enabledBtnBackgroundColor as string);
    document.documentElement.style.setProperty('--disabled-border-color', iframeData?.disabledBorderColor as string);
    document.documentElement.style.setProperty('--disabled-text-color', iframeData?.disabledTextColor as string);
    document.documentElement.style.setProperty('--chip-selected-hover-text-color', iframeData?.chipSelectedHoverTextColor as string);
    document.documentElement.style.setProperty('--chip-hover-border-color', iframeData?.chipHoverBorderColor as string);
    document.documentElement.style.setProperty('--formTextArea-border2-color', iframeData?.formTextAreaBorder2Color as string);
  }, [iframeData?.botCardBackgroundColor, iframeData?.botCardTextColor,iframeData?.chipHoverBackgroundColor,  iframeData?.chipHoverTextColor,iframeData?.chipSelectedColor,  iframeData?.chipSelectedHoverColor,
    iframeData?.userCardBorderColor,iframeData?.infoTextTitleTextColor, iframeData?.meetingCardTitleTextColor, iframeData?.cardInfoChipsBackgroundColor,iframeData?.cardInfoChipsBorderColor, iframeData?.jobDetailsFormMainBackgroundColor, 
    iframeData?.formTextAreaBorderColor, iframeData?.formTextAreaBackgroundColor,  iframeData?.formTextAreaActiveBorderColor,  iframeData?.formTextAreaActiveBackgroundColor,  iframeData?.formSubmitBtnBackgroundColor,  iframeData?.uploadBtnsBackgroundColor,
    iframeData?.uploadBtnsTextColor,iframeData?.chipSelectedBackgroundColor,  iframeData?.chipSelectedBorderColor,iframeData?.chipSelectedHoverBorderColor, iframeData?.enabledBtnBackgroundColor, iframeData?.disabledBorderColor,iframeData?.disabledTextColor,
    iframeData?.chipSelectedHoverTextColor, iframeData?.chipHoverBorderColor,  iframeData?.formTextAreaBorder2Color,iframeData?.chipBgColor
   ]);
 
  //switch code
  const processResponse = (response:any) => {
    setPayloadCount(0);
    let count = 0;
    response?.map((item:any)=>{
      // console.log(item)
      if(item?.fullFillmentText){
        // console.log("added fullfilment");
        count = count +1;
        // console.log(item?.fullFillmentText,item?.fullFillmentText?.length)
      }
      if(item?.richContent){
        // console.log(payloadCount,"payloadCount")
        // console.log(item?.richContent,item?.richContent?.length)
        count = count + item?.richContent[0]?.length;
      }
    })
    // console.log(count,"count from process response")
    setPayloadCount(count)
    setFulfillmentTexts((prevFulfillmentTexts:any) => {
      const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
      return updatedFulfillmentTexts;
    });
    response.forEach((item:any, index:number) => {
      // console.log(item,"item from processResponse")
        if (item.fullFillmentText) {
          // console.log("in FT of processResponse",item);
            displayText(item.fullFillmentText, index);
        } 
        else if (item.richContent) {
          // console.log("in RC of processResponse item",item);
          let data:any[] = [];         
          if(item.richContent.length === 1){
            // console.log("item.richContent[0][0].chip_type");
            if(item.richContent[0]?.length){
              // console.log("heree");              
              if(item.richContent[0][0]){
                // console.log("insideee");                
                if(item.richContent[0][0].chip_type === "job_listing"){
                  data = [{chip_type: "job_listing"}]
                  // console.log("data in job listing",data);
                }  else {
                   data = item.richContent[0];
                }
              }
              else {
                // console.log("......")
                // console.log(item.richContent[0],"e from rich content")

                data = item.richContent[0];
              }
              
            }else{
              // console.log("...sdjfkds...")
              data = [item.richContent[0]];
            }
            // console.log("dataa ",data);
           }else if(item.richContent[0]?.[0]?.chip_type === "horizontal"){
            data = [{chip_type: "horizontal"}]
            // console.log("data",data);
          }          
          else if(item.richContent[0]?.[0]?.chip_type === "job_listing"){
            data = [{chip_type: "job_listing"}]
            // console.log("data in job listing",data);
          }
           else{
            // console.log("..==1....")
            item.richContent.forEach((i:any) => {
              i.forEach((e:any) => {
                // console.log(e,"e from rich content")
                data.push(e);
                // console.log("dtatsd",data);
              })
            });
            
          } 
            data.forEach((j:any, index:number) => {
              // console.log("J--------------------------------",j)

                switch (j.type) {
                    case "chips":
                      if(j?.chip_type){
              // console.log("J------------------------------saadsad--",j?.feedback)

                        displayRichcontent(j.options, "chips", 3,j?.chip_type,j?.feedback);
                      }
                      else{
                        displayRichcontent(j.options, "chips", 3,"",j?.feedback);

                      }
                        break;
                    case "multiselect":
              // console.log("J------------------------------saadsad--",j?.feedback)
                      // console.log("infoooo***",j);
                      if(j.options){
                        displayRichcontent(j.options, "multiselect", 3);
                      }
                        break;
                    case "info":
              // console.log("J------------------------------saadsad--",j?.feedback)
                      // console.log("infoooo***",j);
                      if(j.options){
              // console.log("J------------------------------saadsad--",j?.feedback)

                        displayRichcontent(j.options, "info", 3);
                      }
                      else if(j.faq_flag){
              // console.log("J------------------------------saadsad--",j?.feedback)

                        displayRichcontent([j],"infotext", 0)
                      }
                      else if(j.actionLink){

              // console.log("J------------------------------saadsad--",j?.feedback)

                        displayRichcontent([j],"scheduleMeeting", 0)
                      }
                      else if(j.subtitle){
              // console.log("J------------------------------saadsad--",j?.feedback)

                        displayRichcontent([j],"infotext", 3,"",j?.feedback)
                      }else{
              // console.log("J------------------------------saadsad--",j?.feedback)

                        displayText(j.title,index);
                      }
                        break;
                    
                    case "email":
                      displayRichcontent(item.richContent, "email", 3);
                      break;
            
                    default:
                      break;
                }

                switch (j.chip_type) {                  
                    case "horizontal":
                      // console.log("item.richContent",item.richContent)
                      displayRichcontent(item.richContent,"horizontal",3)
                      break;

                    case "form":
                      displayRichcontent([], "form", 3);
                      break;

                    case "job_listing":
                      // console.log("item.richContent in joblisting",item.richContent)
                      displayRichcontent(item.richContent,"jobListing",3)
                      break;
  
                    default:
                    break;
                }     
              }
            );
        }
    });
  }

  const jobListingResponse = (response:any) => {
    // console.log("inside jobListingResponse response = ", response);
    response.forEach((item:any) => {
      // console.log("jobListingResponse item",item);
      if(item.richContent[0].type === "job_info"){
          const jobResponse = item.richContent.slice(1, 5);
          // console.log("jobResponse = ",jobResponse);
          processResponse(jobResponse);
      }
    })
  }

  const displayText = (text: any, index:number) => {
    // console.log("Text displayText:", text, index);
      const displayTextValues =[ {
          text:text ,
          flag: index === 0,
          isUser: false,
          option: [],
          textLength: 3
        }]
      
      setFulfillmentTexts((prevFulfillmentTexts:any) => [
        ...prevFulfillmentTexts,
        ...displayTextValues,
      ]);
  }

  const displayRichcontent = (chips:any, type:any, index:number,chip_type?:string,feedback?:boolean) => {
    // console.table({chips, type,index,chip_type,feedback})
    // console.log("chips **********",chips);
    const displayRCValues = [{
        text: '',
        type:type,
        flag: feedback?feedback:index === 0,
        isUser: false,
        option: chips,
        textLength: 3,
        chip_type:chip_type || "",
        status:false,
        uid:Math.random()
      }];
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      ...displayRCValues,     
    ]);
  }
      
// Scroll to the bottom of the chat container when fulfillmentTexts change
  // useEffect(() => {
  //   if (fulfillmentTexts.length > 0) {
  //     const chatContainer = document.getElementById("chatContainer");
  //     if (chatContainer) {
  //       chatContainer.scrollTop = chatContainer.scrollHeight;
  //     }
  //   }
  // }, [fulfillmentTexts]);

//according to response height
  // useEffect(() => {
  //   if (fulfillmentTexts.length > 0) {
  //     const chatContainer = chatContainerRef.current;
  //     console.log("chatContainer = ",chatContainer);
  //     if (chatContainer) {
  //       // You can customize the scroll position here.
  //       // For example, scroll to the middle of the chat container.
  //       const middlePosition = chatContainer.scrollHeight / 1.5;
  //       chatContainer.scrollTop = middlePosition;

  //       console.log("chatContainer.scrollHeight = ",chatContainer.scrollHeight);
  //       console.log("chatContainer.scrollTop = ",chatContainer.scrollTop);
        
  //     }
  //   }
  // }, [fulfillmentTexts]);


  //test scroll
  useEffect(() => {
    // console.log(fulfillmentTexts,"fullFillMentText---------------------------------")
    // let child :any, lastChild:any, thirdChild:any;
    if (fulfillmentTexts.length > 0) {
      const chatContainer = document.getElementById("chatContainer");
       
      if (chatContainer) {
        // console.log(chatContainer?.scrollHeight)
        chatContainer.scrollTop = chatContainer.scrollHeight;
        // Capture the current scroll position and scroll height before the new message is added
        const previousScrollHeight = chatContainer.scrollHeight;
        // console.log("previousScrollHeight = ",previousScrollHeight);

        const previousScrollTop = chatContainer.scrollTop;     
        let scrollHeight = 0;
        Array(payloadCount)?.fill(0)?.map((el,index)=>{
          
          //height of each element received from response
          scrollHeight += chatContainer?.children[chatContainer?.children?.length-index-1]?.clientHeight
          // console.log(scrollHeight,"scrollHeight");
        })
        // console.log(scrollHeight,"scrollHeight outside");
        setTimeout(() => {
          // Calculate the new scroll position to align with the start of the new message
          const newScrollPosition = previousScrollTop + (chatContainer.scrollHeight - scrollHeight);

          // Set the scroll position to the start of the new message
          chatContainer.scrollTop = chatContainer?.scrollHeight - (scrollHeight + 20);        
        }, 0);
      }
    }
  }, [fulfillmentTexts]);
  
  
 //animation related useffect
  useEffect(() => {
    // Add a delay to allow DOM updates before applying the animation class
    const timer = setTimeout(() => {
      const messageContainers = document.querySelectorAll(`.${style.slideInRright}`);
      messageContainers.forEach(container => {
        container.classList.add('animated');
      });
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [fulfillmentTexts]);

  const submitForm = (values: { chat: string }) => {
    const { chat } = values;
    const formikHelpers: any = {
      resetForm: () => {},
      setStatus: () => {},
      setErrors: () => {},
      setSubmitting: () => {},
      setTouched: () => {},
      setValues: () => {},
    };
    onSubmitHandler({ chat }, formikHelpers);
  };

  //for sound effect
  const playAudio = (audioRef: React.RefObject<HTMLAudioElement>, delay:number) => {
    if (audioRef.current) {
      setTimeout(() => {
        if(audioRef.current) { // Additional null check
          audioRef.current.play();
          // console.log("audioRef.current", audioRef.current);
        }
      }, delay);
      
    }
  }    
  // console.log("fulfillmentTexts [] = ",fulfillmentTexts)
  // console.log("chatbody session = ",sessionId);
  
  const handleMicClick = async () => {
    if (isRecording) {
      await stopRecordingAndUpload();
    } else {
      await startRecording();
    }
  };

  const startRecording = async () => {
    try {
      start(); //start the recording timer
      setIsRecording(true);
      isInitialState.current = false;
      audioChunksRef.current = []; // Clear previous audio chunks
  
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  
      const recorder = new MediaRecorder(stream);
      recorderRef.current = recorder;
  
      recorder.ondataavailable = (event) => {
        console.log("Audio chunk received:", event.data.size);
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
  
      recorder.onstop = () => {
        console.log("Recording stopped.");
        // Ensure the stream tracks are stopped
        stream.getTracks().forEach((track) => track.stop());
      };  
      recorder.start();
      console.log("Recording started.");
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setIsRecording(false);
    }
  };

  // console.log("openailoader = ",openAiLoader);
  const stopRecordingAndUpload = async () => {
    setOpenAiLoader(true);
    try {
      const recorder = recorderRef.current;
      if (recorder && recorder.state === "recording") {
        recorder.stop();
        setIsRecording(false);
  
        // Wait briefly to ensure all chunks are processed
        setTimeout(async () => {
          const audioChunks = audioChunksRef.current;
          if (audioChunks.length === 0) {
            console.error("No audio data recorded.");
            return;
          }
  
          // Convert chunks to a single blob
          const blob = new Blob(audioChunks, { type: "audio/webm" });
          // console.log("Blob created:", blob);
  
          // Prepare FormData for API upload
          const formData = new FormData();
          formData.append("file", blob, "audio.webm");
          formData.append("model", "whisper-1");
          formData.append("language", "en");
          formData.append("prompt","I want to transcribe audio with high accuracy, especially for email addresses. The transcription should correctly identify and include the @ symbol in email addresses. For example, if the user says 'example@gmail.com,' the output must accurately reflect it as 'example@gmail.com' without substituting or omitting the @. Please ensure that email addresses are handled appropriately and formatted correctly in the transcription output");
  
          // Upload to transcription API
          try {
            const response = await axios.post(TRANSCRIPTION_API_URL, formData, {
              headers: {
                Authorization: `Bearer ${API_KEY}`,
                "Content-Type": "multipart/form-data",
              },
            });
  
            // console.log("Transcription response:", response.data.text);
            setOpenAiLoader(false);
            setResponseQuery(response.data.text);
            
            // Handle the transcription result (update state, show in UI, etc.)
          } catch (error) {
            console.error("Error during transcription API call:", error);
          }
        }, 100); // Adjust delay if necessary
      }
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  };

  //edit the recorded audiochunk displayed in the textfield
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // Update local responseQuery state
    if (responseQuery !== null) {
      setResponseQuery(value);
    }
  };

  // console.log("isInitialState = ",isInitialState.current);
  return (
    <div style={{display:"flex", flexDirection:"column", flexGrow:1, overflow:"hidden"}}>
      <div className={style.chatBody} id="chatContainer">
        {fulfillmentTexts.length > 0 && fulfillmentTexts.map((text: any, index: number) => 
        {
          // console.log(text,"text form compo")
          return(
            <div key={index}>
              {text.isUser && (
                <div className={`${style.userMessage} ${style.slideInRright}`}>
                  <div className={`${style.box} ${style.userMess}`}>
                    <Card variant="outlined" className={style.userCard}>
                      {text.text}
                    </Card>
                  </div>
                </div>
              )}
              {!text.isUser && (
                <div className={style.botMessage}>
                  <div className={`${style.box} ${style.chatbotImg}`}>
                    {text.flag === true && (
                      <img src={iframeData?.bodyAvatar} alt="Bot Icon" className={style.chatLogo}/>
                    )}
                  </div>
                  
                  <div className={`${style.box} ${style.fadeIIn}`}>
                      <div className={style.cardContainer}>
                        {Boolean(text.text) && text.textLength !== 2000 && (
                          <Card variant="outlined" className={style.botCard}>
                            {text.text}
                          </Card>
                        )}
                        {text.textLength === 2000 && <Loader />}
                      </div>
                      
                      { <div className={style.chipsMain}>
                          {text?.type === "chips" && (
                              <div className={style.chipsContainer}>
                                {text?.option?.map(
                                  (option: any, index: number) => (
                                    <Chip sx={{
                                      height: 'auto',
                                      '& .MuiChip-label': {
                                        display: 'block',
                                        whiteSpace: 'normal',
                                        padding: '6px 13px'
                                      },
                                    }}
                                      key={index}
                                      // className={`${style.chips} ${isChipActive(text?.uid || 0, index) ? 'selected' : ''}`}
                                      label={option?.text}
                                      href={option?.link}
                                      className={style.chipsStylesChk}
                                      component="a"
                                      style={
                                        option?.status?
                                          {
                                            background: iframeData?.chipSelectedBackgroundColor,
                                            border: '1px solid #F5C142',
                                            color: iframeData?.chipSelectedColor, 
                                            marginRight: '10px',
                                            marginBottom:'15px',
                                            fontSize: '12px',
                                            lineHeight: '15px',
                                            width: 'fit-content',
                                            // maxWidth: '270px',
                                            minHeight: '30px',
                                            height: 'auto',
                                            fontFamily:`Inter, sans-serif`,
                                            // transition: 'background 0.3s, border-color 0.3s, color 0.3s'
                                            }
                                        :
                                        isChipActive(text?.uid || 0, index)
                                              ? {
                                                background: iframeData?.chipSelectedBackgroundColor,
                                                border: '1px solid #F5C142',
                                                color: iframeData?.chipSelectedColor, 
                                                marginRight: '10px',
                                                marginBottom:'15px',
                                                fontSize: '12px',
                                                lineHeight: '15px',
                                                width: 'fit-content',
                                                // maxWidth: '270px',
                                                minHeight: '30px',
                                                height: 'auto',
                                                fontFamily:`Inter, sans-serif`,
                                                // transition: 'background 0.3s, border-color 0.3s, color 0.3s'
                                                }
                                              : { 
                                                opacity: 1,
                                                background: 'rgb(14, 73, 122)',
                                                color: 'rgb(255, 255, 255)',
                                                marginRight: '0px',
                                                marginBottom: '15px',
                                                fontSize: '12px',
                                                lineHeight: '15px',
                                                width: 'fit-content',
                                                // maxWidth: '270px',
                                                minHeight: '30px',
                                                height: 'auto',
                                                fontFamily: 'Inter, sans-serif',
                                                transition: 'background 0.3s ease 0s, border-color 0.3s ease 0s, color 0.3s ease 0s'
                                                }
                                            }
                                      onClick={() => {

                                        if(text?.chip_type==="multiselect"){
                                          // console.log(option?.text)
                                          handleMultiChipClick(index, option.text, text.uid,option)
                                        }
                                        else{
                                          handleChipClick(index, option.text, text.uid); 
                                        }
                                      }}
                                       disabled={isChipActive(text?.uid || 0, index) ? false : activeChips[text?.uid] !== undefined} 
                                    />
                                  )
                                )}
                              </div>
                            )
                          }
                        </div>
                      }
                     
                      {/* { text?.type === "multiselect"?
                      
                      <Chip sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                          padding: '6px 13px'
                        },
                      }}
                        // className={`${style.chips} ${isChipActive(text?.uid || 0, index) ? 'selected' : ''}`}
                        label={"Submit"}
                        className={style.chipsStylesChk}
                        component="button"
                        style={{ 
                                  opacity: 1,
                                  border: '1px solid rgb(14, 73, 122)',
                                  color: ' rgb(14, 73, 122)',
                                  marginRight: '0px',
                                  marginBottom: '15px',
                                  fontSize: '12px',
                                  lineHeight: '15px',
                                  width: 'fit-content',
                                  // maxWidth: '270px',
                                  minHeight: '30px',
                                  height: 'auto',
                                  fontFamily: 'Inter, sans-serif',
                                  transition: 'background 0.3s ease 0s, border-color 0.3s ease 0s, color 0.3s ease 0s'
                                  }
                              }
                        onClick={() => {handleMultiSelectApi(index) }}
                        //  disabled={isChipActive(text?.uid || 0, index) ? false : activeChips[text?.uid] !== undefined} 
                      />
                      // <CustomMultiSelect options={text}/>
                      :null} */}


                        { text?.type === "multiselect" && 
                       ( 
                          <CustomMultiSelect options={text} onSubmit={handleMultiSelectApi} index={index}/>
                        )}
                     
                      {text.type === "horizontal" && (
                        <div className={style.chipsMain} style={{marginLeft:'-35px'}}>
                          {text?.option?.length > 0 && (
                            <div className={style.wrapper}>
                              <button onClick={(e) => moveleft(e, text.uid)} className={style.slideleftContainer} >
                                <img src={next} alt="next" className={style.arrowImg}/>
                              </button>
                              <div className={style.cardOuterContainer}>
                                <div className={style.cardInnerContainer} id={text.uid}>
                                  <div className={style.chipsContainerCard}>
                                    {text?.option?.map((option: any, index: number) => (
                                        <CardInfo
                                          response={option}
                                          key={index}
                                          onChipClick={onChipClick}
                                        ></CardInfo>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <button onClick={(e) => moveright(e, text.uid, text.option.length)} className={style.sliderightContainer} >
                                <img src={next} alt="previous" className={style.arrowImg}/>
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {text.type === "jobListing" && (
                        <div className={style.chipsMain} 
                        style={{ marginLeft: text.option.length > 1 ? '-35px' : '0' }}
                        >
                          {text?.option?.length > 0 && (
                            <div className={style.wrapper} style={{maxWidth:'21rem'}}>
                              {text.option.length > 1 && (
                              <button onClick={(e) => moveleft(e, text.uid)} className={style.slideleftContainer} style={{marginTop:'5rem'}}>
                                <img src={next} alt="next" className={style.arrowImg}/>
                              </button>
                              )}
                              <div className={style.cardOuterContainer} style={{height:'210px', maxWidth:'22rem'}}>
                                <div className={style.cardInnerContainer} id={text.uid} style={{height:'230px'}}>
                                  <div className={style.chipsContainerCard}>
                                    {text?.option?.map((option: any, index: number) => (
                                        <JobListing
                                          response={option}
                                          key={index}
                                          onChipClick={onChipClick}
                                        ></JobListing>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              {text.option.length > 1 && (
                              <button onClick={(e) => moveright(e, text.uid, text.option.length)} className={style.sliderightContainer} style={{marginTop:'5rem'}}>
                                <img src={next} alt="previous" className={style.arrowImg}/>
                              </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {text.type === "infotext" && (
                        <>                  
                          {(
                            <>
                              {text?.option?.map(
                                (options: any, index: number) => {
                                 
                                    // console.log(options)
                                    return(
                                    <InfoText startIdleTimer={startIdleTimer} setFulfillmentTexts={setFulfillmentTexts} getResponse={getResponse} response={[options]} key={index} sessionId={sessionId}></InfoText>
                                  )
                              }
                              )}
                            </>
                          )}
                        </>
                      )}

                      {text.type === "scheduleMeeting" && (
                        <>
                          {/* <MeetingCard response={text}/> */}
                          {text?.option?.length > 0 && (
                            <>
                              {text?.option?.map((options: any, index: number) => (
                                  <MeetingCard
                                    response={options}
                                    key={index}></MeetingCard>
                                )
                              )}
                            </>
                          )}
                        </>
                      )}

                      {text.type === 'form' && <JobDetailsForm  onCombinedValues={handleCombinedValues} sessionId={sessionId} />}
                    
                      {text.type === 'email' && <ResumeUpload selectedFile={uploadFile} setSelectedFile={setUploadFile} onSendResume={handleSendResume} />}
                  </div>
                </div>
              )}
            </div>
          )})
        }
      </div>

      <StyledModalContainer className={style.formikk}>
        <Formik initialValues={INITIAL_VALUES} 
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmitHandler} >
          {({handleSubmit,handleBlur,values}) => (
            <Form onSubmit={handleSubmit}>
              <div className={style.textfieldWrapper}>
                <TextField
                  id="chat"
                  type="text"
                  name="chat"
                  disabled = {isRecording || openAiLoader}
                  className={style.textfield}
                  // multiline
                  // value={isRecording ? `${minutes}:${seconds}` : values.chat}
                  value={isRecording ? undefined : (responseQuery ? responseQuery : values.chat)}
                  placeholder={ fileSizeError || (
                  (isInitialState.current ? "Click to begin" : isRecording ? "Listening...": openAiLoader ? 'Wait for the response' : "Type your message")
                    // (isInitialState.current ? "Please type in your query here" : "Please type in your query here")
                  )}
                  onChange={handleInputChange}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSubmit();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* emoji */}
                        <IconButton>
                          {isRecording?<img src={RecordingGif} className={style.recordingIcon}/>: null
                        }
                        </IconButton>
                        {/* mic */}
                      
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {(values?.chat || responseQuery)? (
              <button type="submit" className={style.sendbtn}>
                <img src={iframeData?.btnIcon} alt="SendButton" className={style.sendbtn} />
              </button>
           ) : openAiLoader ? (
           <div className={style.loaderBtn}>
           <ColorRing
            visible={true}
            height="30"
            width="30"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={[
              "#808080",
              "#808080",
              "#808080",
              "#808080",
              "#808080"
            ]}
          />
            </div>): (
            <button
                type="button"
                onClick={handleMicClick}
                className={
                  isRecording ? style.micRecording : style.micButton
                }
               >
            <Mic />
          </button>
           ) }
            {/* } */}
            </Form>
          )}
        </Formik>
      </StyledModalContainer>
      <div className={style.footerWrapper}>
        <StyledFooter>
          <div className={style.footertext}>
            Powered by<span className={style.oxylym}>Oxylym</span>
          </div>
        </StyledFooter>
      </div>
      {/* <audio ref={responsesReceivedRef} id="fadeInSound" controls={false} style={{display:'none'}}>
      <source src="/Sounds/incoming_msg.mp3" type="audio/mp3" />
      </audio>
      <audio ref={formChatRef} id="slideInRightSound" controls={false} style={{display:'none'}}>
        <source src="/Sounds/send.mp3" type="audio/mp3" />
      </audio> */}
      {/* <audio ref={chipClickRef} id="chipClickSound" controls={false} style={{display:'none'}}>
        <source src="/Sounds/opening_card.mp3" type="audio/mp3" />
      </audio> */}
    </div>
  );
};

export default ChatBody;


